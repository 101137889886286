import React from "react"
import { graphql, StaticQuery } from "gatsby"
import "./index.scss"

const Form = () => (
  <section className="form">
    <form
      className="form__form"
      method="POST"
      netlify
      netlify-honeypot="bot-field"
    >
      <div className="form__form-short-fields">
        <div className="form__form-item">
          <label>
            First Name<sup>*</sup>
          </label>
          <input type="text" name="name" id="name" required />
        </div>

        <div className="form__form-item">
          <label>
            Last Name<sup>*</sup>
          </label>
          <input type="text" name="name" id="name" required />
        </div>

        <div className="form__form-item">
          <label>
            Email Address<sup>*</sup>
          </label>
          <input type="email" name="email" id="email" required />
        </div>

        <div className="form__form-item">
          <label>Title</label>
          <input type="text" name="subject" id="subject" />
        </div>

        <div className="form__form-item">
          <label>Place of work</label>
          <input type="text" name="subject" id="subject" />
        </div>

        <div className="form__form-item">
          <label>Work address</label>
          <input type="text" name="subject" id="subject" />
        </div>
      </div>

      <div className="form__form-item">
        <label>Comments</label>
        <textarea name="message" id="message" rows="5" />
      </div>

      <div className="form__form-item submit">
        <span></span>
        <button className="button" type="submit">Submit</button>
      </div>
    </form>
  </section>
)

export default Form
