import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import Form from "src/components/form"
import Breadcrumbs from "src/components/Breadcrumbs"
import SubpageBanner from "src/components/SubpageBanner"
import IntroHeading from "src/components/IntroHeading"

const breadcrumbs = [{ url: "contact", handle: "Contact Us" }]

const ContactPage = () => (
  <StaticQuery
    query={graphql`
      query {
        background: file(relativePath: { eq: "header-clinical-sample.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const background = data.background.childImageSharp.fluid

      return (
        <Layout>
          <SEO title="Contact Us" />
          <SubpageBanner image={background} heading="Contact Us" />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <div className="contain wrapper">
              <IntroHeading copy="Have questions about how exCellerator aids the ThinPrep Pap Test System? Contact us today:" />
              <Form />
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default ContactPage
